import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Letters from "./components/Letters";
import AboutModal from "./components/AboutModal";
import LoadingPage from "./components/LoadingPage";
import "./App.css";
import "./styles/colors.css";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [titleModal, setTitleModal] = useState("About");

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleShowAbout = () => {
    setIsModalOpen(!isModalOpen);
    setTitleModal("ABOUT");
  };

  const handleShowHow = () => {
    setIsModalOpen(!isModalOpen);
    setTitleModal("HOW WE WORK");
  };

  const handleShowContactInfo = () => {
    setIsModalOpen(!isModalOpen);
    setTitleModal("CONTACT");
  };

  const handleBackHome = () => {
    setIsModalOpen(false);
  };

  if (isLoading) return <LoadingPage />;
  return (
    <div className="App">
      <div className="overlay"></div>
      <video
        autoPlay
        loop
        muted
        playsInline
        className="background-video"
        preload="auto"
      >
        <source src="/background.mp4" type="video/mp4" />
      </video>
      <Header
        onAboutClick={handleShowAbout}
        onHowClick={handleShowHow}
        handleShowContact={handleShowContactInfo}
        handleBackHome={handleBackHome}
      />
      {!isModalOpen && <Letters />}
      <AboutModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalTitle={titleModal}
      />
    </div>
  );
};

export default App;
