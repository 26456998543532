import React, { useCallback, useEffect } from "react";
import { X } from "lucide-react";
import "../index.css";
import "../styles/aboutModal.css";
import EmailModal from "./EmailModal";

const AboutModal = ({ isModalOpen, setIsModalOpen, modalTitle }) => {
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        setIsModalOpen(false);
      }
    },
    [setIsModalOpen]
  );

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isModalOpen, handleKeyDown]);

  return (
    <div
      className={`modalContainer fixed left-0 right-0 bottom-0 mx-auto transition-transform duration-300 ease-out transform ${
        isModalOpen ? "translate-y-0" : "translate-y-full"
      }`}
      style={{ top: "100px", maxHeight: "90vh", zIndex: 1, maxWidth: "90%" }}
      onClick={() => setIsModalOpen(false)}
    >
      <div className="max-w-4xl mx-auto h-full flex flex-col backdrop-blur-md bg-white bg-opacity-0 rounded-t-lg shadow-lg">
        <div className="p-6 border-b border-white border-opacity-10">
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-bold text-white">{modalTitle}</h2>
            <button
              onClick={() => setIsModalOpen(false)}
              className="text-white hover:text-gray-200"
            >
              <X size={24} />
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          <div className="p-6 space-y-4 text-white">
            {modalTitle === "ABOUT" ? (
              <>
                <p>
                  TLS provides sustainable omni-channel training solutions for
                  brands competing in luxury retail.
                </p>
                <p>
                  <b>We promote Human Experience (HX) </b>
                  as a key asset and crafting extraordinary experiences through
                  our exclusive haute couture methodology.
                </p>
                <p>
                  We leverage insights from more than 10 local cultures,
                  supported by a network of over 30 expert consultants. We
                  customize our training solutions to resonate deeply with
                  diverse audiences, ensuring every engagement is both
                  purposeful and impactful.
                </p>
                <p>
                  We CONNECT, COLLABORATE, and CREATE to expedite the
                  implementation of your retail strategies, maximizing the
                  impact of delivering exceptional customer experiences that
                  drive long-term success for your brand.
                </p>
              </>
            ) : modalTitle === "HOW WE WORK" ? (
              <>
                <h3 className="font-bold">Identify</h3>
                <p>
                  Immerse ourselves in your brand's universe to understand its
                  current position in the luxury retail landscape. Define
                  objectives, uncover strengths, and identify opportunities to
                  accurately assess training needs.
                </p>
                <h3 className="font-bold">Innovate</h3>
                <p>
                  Create, design, and deliver innovative training programs to
                  elevate and transform the brand's Customer Experience (CX) on
                  a global scale. By assessing how we train, what we train, and
                  why we train, we combine market research, store health audits,
                  and in-person implementation to drive sustainable change and
                  measurable results.
                </p>
                <h3 className="font-bold">Inspire</h3>
                <p>
                  Inspire the skills and confidence to translate knowledge into
                  action in real-world settings through post-training solutions
                  that bridge the gap from knowing to doing, ensuring lasting
                  impact and practical application.
                </p>
              </>
            ) : (
              <>
                <EmailModal />
              </>
            )}
          </div>
        </div>

        <div className="p-6 border-t border-white border-opacity-10">
          <div className="flex justify-end">
            <button
              onClick={() => setIsModalOpen(false)}
              className="bg-white bg-opacity-20 text-white px-6 py-3 rounded-md hover:bg-opacity-30 transition duration-300 ease-in-out text-lg"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutModal;
