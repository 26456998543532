import React from "react";
import "../styles/loadingPage.scss";

const LoadingPage = () => {
  return (
    <>
      <div className="c-planet">
        <div className="c-orbit">
          <div className="c-plane"></div>
        </div>
      </div>
      <div className="cities">
        <span className="city">Shanghai</span>
        <span className="city">Paris</span>
        <span className="city">New York</span>
      </div>
    </>
  );
};

export default LoadingPage;
