import React, { useState } from "react";
import { FaCopy } from "react-icons/fa";
import "../styles/emailModal.css";

const EmailModal = () => {
  const email = "hello@thelivestreamgroup.com";
  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(email)
      .then(() => setCopySuccess("Email copied!"))
      .catch(() => setCopySuccess("Failed to copy email."));
  };

  return (
    <div className="modal-body">
      <div className="email-container">
        <p>{email}</p>
        <button className="copy-btn" onClick={copyToClipboard}>
          <FaCopy />
        </button>
      </div>
      {copySuccess && <p className="copy-success">{copySuccess}</p>}
    </div>
  );
};

export default EmailModal;
