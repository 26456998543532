import React from "react";
import "../styles/header.css";

const Header = ({
  onAboutClick,
  onHowClick,
  handleShowContact,
  handleBackHome,
}) => {
  return (
    <header className="header">
      <div className="logo" onClick={handleBackHome}>
        <img src="/assets/logo.png" alt="Logo TLS" />
      </div>
      <nav className="nav">
        <a href="#about" onClick={onAboutClick}>
          About
        </a>
        <a href="#about" onClick={onHowClick}>
          How we work
        </a>
        <a href="#contact" onClick={handleShowContact}>
          Contact
        </a>
      </nav>
    </header>
  );
};

export default Header;
