import React from "react";
import "../styles/letters.css";

const Letters = () => {
  return (
    <div className="letters-container">
      <div className="letter">
        T
        <div className="letter-text">
          <p>Training program design & delivery.</p>
          <p>Training content creation & production.</p>
          <p>Training project execution & localisation.</p>
          <p>Training tool & digital platform development.</p>
        </div>
      </div>
      <div className="letter">
        L
        <div className="letter-text">
          <p>
            Leverage Virtual Reality & Mixed Reality for immersive learning.
          </p>
          <p>Lift in-store client engagement with cutting-edge technologies.</p>
          <p>
            Localise training for diverse markets with a multilingual approach.
          </p>
          <p>
            Launch transformative training initiatives to enhance retail
            performance.
          </p>
        </div>
      </div>
      <div className="letter">
        S
        <div className="letter-text">
          <p>Supercharge frontline teams through tailored training.</p>
          <p>Synchronize design and training strategies.</p>
          <p>Sync training efforts with retail management goals.</p>
          <p>Streamline coaching practices for sustained success.</p>
        </div>
      </div>
    </div>
  );
};

export default Letters;
